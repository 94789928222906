<template>
    <div>
        <template v-if="!edit">
            <div @click="edit = true">
                <div v-if="currentValue != ''">
                    <span v-html="currentValueBR"></span> <font-awesome-icon :icon="['fal', 'edit']" />
                </div>
                <div v-else class="btn btn-link">
                    {{ $t("gynecologie.ajouter_commentaire") }} <font-awesome-icon :icon="['fal', 'edit']" />
                </div>
            </div>
        </template>
        <template v-else>
            <textarea v-model="newValue" class="form-control"></textarea>
            <button @click.prevent="startSave" class="btn btn-primary btn-block mt-3">{{ $t("global.sauvegarder") }} <font-awesome-icon :icon="['fal', 'save']" /></button>
        </template>
    </div>
</template>

<script>
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    
    export default {
        props: ['value'],
        data () {
            return {
                currentValue: null,
                newValue: null,
                edit: false
            }
        },
        mounted() {
            EventBus.$on("TextAreaInput::stopSave", (success) => {
                if(success) {
                 this.stopSave()
                } else {
                 //
                }
            });

            this.init_component()
        },
        methods: {
            init_component() {
                this.currentValue = this.newValue = this.value
                this.edit = false
            },
            async startSave() {
                EventBus.$emit('TextAreaInput::startSave', this.newValue)
            },
            stopSave() {
                this.currentValue = this.newValue
                this.edit = false
            }
        },
        computed: {
            currentValueBR() {
                return this.currentValue ? this.currentValue.replaceAll('\n', '<br>') : ''
            }
        },
        watch: {
            value() {
                this.init_component()
            }
        }
    }
</script>
